<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
            <filter-regional v-model:value="regionals" />
          </div>
          <div class="card-body mt-2 ml-2">
             <filter-kabupaten-kota v-model:value="kabupaten" v-model:area="areas" />
          </div>
          <div class="card-body mt-2 ml-2">
             <a-date-picker v-model:value="value2" :format="dateFormatList" placeholder="Pilih Tanggal Mulai" />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
             <filter-province v-model:value="province" v-model:regional="regionals" />
          </div>
          <div class="card-body mt-2 ml-2">
             <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Toko">
            <a-select-option value="fajar_mulia_bogi">Fajar Mulia Bogi</a-select-option>
            <a-select-option value="kunci_mas">Kunci Mas</a-select-option>
            <a-select-option value="al_yamin">AL Yamin</a-select-option>
            <a-select-option value="mulia_baru">Mulia Baru</a-select-option>
             </a-select>
          </div>
          <div class="card-body mt-2 ml-2">
             <a-date-picker v-model:value="value2" :format="dateFormatList" placeholder="Sampai Tanggal" />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
            <filter-area v-model:value="areas" v-model:province="province" />
          </div>
          <div class="card-body ml-2 mt-2">
             <a-input-search
              v-model:value="value"
              placeholder="Cari ..."
              style="width: 300px"
              @search="onSearch"
            />
          </div>
        </div>
        <div class="card-placeholder">
          <div class="card-body">
             <a-button class="m-2" type="primary" title="cari" @click="search">
              <i class="fa fa-search" aria-hidden="true"></i>
            </a-button>
              <a-button
              type="primary"
              stylea="width: 200px; background-color: #3acf41; border: none;"
              title="Download Xls"
              @click="fetchXlsx"
            >
              <i class="fa fa-file-excel-o" aria-hidden="true"></i>
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment, { Moment } from 'moment';
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
// import MModal from './modal'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
export default {
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    // FilterDistributor,
  },
  setup() {
    const dateFormat = 'YYYY/MM/DD';
    const monthFormat = 'YYYY/MM';
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    return {
      value2: ref<Moment>(moment('01/01/2015', dateFormatList[0])),
      dateFormat,
      monthFormat,
      dateFormatList,
      moment,
    };
  },
};
</script>
